@font-face {
    font-family: "Merienda";
    src: url("Merienda-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Merienda";
    src: url("Merienda-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Dancing Script";
    src: url("DancingScript-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Dancing Script";
    src: url("DancingScript-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Dancing Script";
    src: url("DancingScript-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Dancing Script";
    src: url("DancingScript-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
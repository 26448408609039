@font-face {
    font-family: "Roboto Slab";
    src: url("RobotoSlab-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto Slab";
    src: url("RobotoSlab-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto Slab";
    src: url("RobotoSlab-ExtraBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto Slab";
    src: url("RobotoSlab-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto Slab";
    src: url("RobotoSlab-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto Slab";
    src: url("RobotoSlab-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto Slab";
    src: url("RobotoSlab-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto Slab";
    src: url("RobotoSlab-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto Slab";
    src: url("RobotoSlab-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}